<template>
  <footer id="index-footer" class="ant-layout-footer new-index-footer">
    <section class="footer-top-container">
      <div class="product-and-servers">
        <div class="footer-top-title">服务支持</div>
        <div class="footer-top-item">
          <a href="javascript:void(0);" @click="goPCService(0)">帮助中心</a>
        </div>
        <!-- <div class="footer-top-item">
          <a href="javascript:void(0);" @click="goPCService(1)">服务协议</a>
        </div>
        <div class="footer-top-item">
          <a href="javascript:void(0);" @click="goPCService(2)">隐私政策</a>
        </div> -->
      </div>
      <div class="about-us">
        <div class="footer-top-title">关于我们</div>
        <div class="footer-top-item">
          <a href="javascript:void(0);" @click="goPCAbout(0)">关于我们</a>
        </div>
        <div class="footer-top-item">
          <a href="javascript:void(0);" @click="goPCAbout(1)">荣誉成就</a>
        </div>
        <div class="footer-top-item">
          <a href="javascript:void(0);" @click="goPCAbout(2)">合作伙伴</a>
        </div>
      </div>
      <div class="contact-us">
        <div class="footer-top-title">联系我们</div>
        <div class="footer-top-item">
          <a href="javascript:void(0);" @click="showGuidance">留言咨询</a>
        </div>
        <div class="footer-top-item">业务联系：15705811892</div>
        <div class="footer-top-item">客服热线：400-996-3000</div>
        <div class="footer-top-item">帮助支持：bzp888@iyunmu.com</div>
      </div>
      <div class="look-us">
        <div class="footer-top-title">关注我们</div>
        <div class="look-us-content">
          <div class="footer-top-item">
            <p>公众号</p>
            <div class="code-img">
              <div>
                <img
                  src="https://b.iyunmu.com/oss/bzp/b527d23f449933e2f79cdc196d486e4f.png"
                />
              </div>
            </div>
          </div>
          <div class="footer-top-item">
            <p>小程序</p>
            <div class="code-img">
              <div>
                <img
                  src="https://b.iyunmu.com/oss/bzp/347b299976bcabc6e51abe6a41d99cdc.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footer-bottom-container">
      <div class="footer-author-intro">
         <span>Copyright©2022-2025&nbsp;&nbsp;包租婆管家版权所有&emsp;</span>
         <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow"
           style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
            margin-right: 30px;
          ">浙ICP备16031720号-2
        </a>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802013012"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="https://b.iyunmu.com/oss/bzp/814989b983fd853fb374e1676a06ade4.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            浙公网安备 33010802013012号
          </p></a
        >
      </div>
      <!-- <div class="footer-bootom-last">
        <span>Copyright©2022-2025&nbsp;&nbsp;包租婆管家版权所有&emsp;</span>
        <span>地址：浙江省杭州市滨江区浦沿街道伟业路3号C幢</span>
      </div> -->
    </section>
    <Toolbox style="visibility: hidden" ref="Toolbox" />
  </footer>
</template>

<script>
import Toolbox from "@/components/PCToolbox.vue";

export default {
  components: {
    Toolbox,
  },
  methods: {
    goPCService(key) {
      this.$router
        .push({
          name: "PCService",
          params: {
            key,
          },
        })
        .catch((err) => {
          this.$bus.$emit("serKeyChange", key);
        });
    },
    goPCAbout(key) {
      this.$router
        .push({
          name: "PCAbout",
          params: {
            key,
          },
        })
        .catch((err) => {
          this.$bus.$emit("aboutKeyChange", key);
        });
    },
    showGuidance() {
      this.$refs.Toolbox.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#index-footer {
  -webkit-box-pack: center;
  -webkit-box-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  align-items: center;
  background: #fff;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
}

#index-footer.new-index-footer {
  background: #1f2431;
  padding: 78px 0 58px;
}

#index-footer.new-index-footer .footer-top-container {
  -webkit-box-pack: justify;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 40px;
  width: 1200px;
}

#index-footer.new-index-footer .footer-top-container > div:not(:last-child) {
  margin-right: 130px;
}

#index-footer.new-index-footer .footer-top-container .footer-top-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  white-space: nowrap;
  word-break: keep-all;
  text-align: left;
}

#index-footer.new-index-footer .footer-top-container .footer-top-item {
  color: #8d9197;
  font-weight: normal;
  font-size: 15px;
  margin-bottom: 18px;
  white-space: nowrap;
  word-break: keep-all;
  text-align: left;
}

#index-footer.new-index-footer .footer-top-container .footer-top-item > a {
  color: #8d9197;
}

#index-footer.new-index-footer .footer-top-container .footer-top-item > a:hover {
  color: #fff;
}

#index-footer.new-index-footer .footer-top-container .look-us .look-us-content {
  display: -webkit-box;
  display: flex;
}

#index-footer.new-index-footer
  .footer-top-container
  .look-us
  .look-us-content
  .footer-top-item {
  margin-right: 42px;
}

#index-footer.new-index-footer
  .footer-top-container
  .look-us
  .look-us-content
  .footer-top-item:last-child {
  margin-right: 0;
}

#index-footer.new-index-footer
  .footer-top-container
  .look-us
  .look-us-content
  .footer-top-item
  > p {
  margin-bottom: 12px;
}

#index-footer.new-index-footer
  .footer-top-container
  .look-us
  .look-us-content
  .footer-top-item
  .code-img {
  border-radius: 3px;
  height: 112px;
  overflow: hidden;
  width: 112px;
}

#index-footer.new-index-footer
  .footer-top-container
  .look-us
  .look-us-content
  .footer-top-item
  .code-img
  > div {
  background: #3d4b69;
  border: 1px solid transparent;
  -webkit-border-image: -webkit-linear-gradient(top, #9aabd1, #303a50) 1 1 round;
  -o-border-image: linear-gradient(180deg, #9aabd1, #303a50) 1 1 round;
  border-image: linear-gradient(180deg, #9aabd1, #303a50) 1 1 round;
  border-radius: 3px;
  box-shadow: 0 13px 21px 0 rgba(46, 53, 77, 0.37), 0 4px 29px -3px rgba(0, 0, 0, 0.21);
  height: 112px;
  position: relative;
  width: 112px;
}

#index-footer.new-index-footer
  .footer-top-container
  .look-us
  .look-us-content
  .footer-top-item
  .code-img
  > div
  img {
  bottom: 0;
  height: 102px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 102px;
}

#index-footer.new-index-footer .footer-bottom-container {
  margin: auto;
  padding-top: 42px;
  width: 1200px;
}

#index-footer.new-index-footer .footer-bottom-container a {
  color: #8d9197;
  margin-right: 12px;
}

#index-footer.new-index-footer .footer-bottom-container a:hover {
  color: #fff;
  font-size: 14px;
}

#index-footer.new-index-footer .footer-bottom-container span {
  color: #8d9197;
  margin-right: 12px;
}

#index-footer.new-index-footer .footer-bottom-container .footer-logo {
  display: -webkit-box;
  display: flex;
  margin-bottom: 20px;
}

#index-footer.new-index-footer .footer-bottom-container .footer-logo > div:first-child {
  margin-right: 32px;
  position: relative;
}

#index-footer.new-index-footer
  .footer-bottom-container
  .footer-logo
  > div:first-child:after {
  background: #757f9f;
  content: "";
  height: 16px;
  position: absolute;
  right: -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
}

#index-footer.new-index-footer .footer-bottom-container .footer-logo > div img {
  width: 116px;
}

#index-footer.new-index-footer .footer-bottom-container .footer-author-intro {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  text-align: left;
}

#index-footer.new-index-footer .footer-bottom-container .footer-bootom-last {
  text-align: left !important;
}

#index-footer.new-index-footer
  .footer-bottom-container
  .footer-bootom-last
  .footer-bootom-left {
  -webkit-box-flex: 1;
  flex: 1;
}

#index-footer.new-index-footer
  .footer-bottom-container
  .footer-bootom-last
  .footer-bootom-right {
  text-align: right;
  width: 160px;
}

#index-footer.new-index-footer
  .footer-bottom-container
  .footer-bootom-last
  .footer-bootom-right
  a:first-child {
  margin-right: 28px;
  position: relative;
}

#index-footer.new-index-footer
  .footer-bottom-container
  .footer-bootom-last
  .footer-bootom-right
  a:first-child:after {
  background: #757f9f;
  content: "";
  height: 16px;
  position: absolute;
  right: -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
}

#index-footer.new-index-footer
  .footer-bottom-container
  .footer-bootom-last
  .footer-bootom-right
  a:last-child {
  margin-right: 0;
}

#index-footer .footer-container {
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 1280px;
  padding: 47px 40px 20px;
  width: 100%;
}

#index-footer .footer-container,
#index-footer .left {
  -webkit-box-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: flex;
}

#index-footer .left {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

#index-footer .left .logo {
  width: 103px;
}

#index-footer .left .desc {
  color: #282d37;
  font-size: 14px;
  line-height: 20px;
}

#index-footer .left .desc .company-number {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  margin-bottom: 4px;
  margin-top: 16px;
  text-align: left;
}

#index-footer .left .desc .company-number > a {
  color: #282d37;
  display: inline-block;
}

#index-footer .left .desc .company-number .record-number {
  display: inline-block;
  height: 20px;
  margin-left: 38px;
  width: 300px;
}

#index-footer .left .desc .company-number .record-number > a {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  height: 20px;
  line-height: 20px;
  text-decoration: none;
}

#index-footer .left .desc .company-number .record-number > a > img {
  width: 18px;
}

#index-footer .left .desc .company-number .record-number > a > p {
  color: #282d37;
  height: 20px;
  line-height: 20px;
  margin-left: 3px;
}

#index-footer .left .desc p {
  margin: 0;
}

#index-footer .left .desc .copyright {
  margin: 0 24px 0 0;
  text-align: left;
}

#index-footer .right {
  color: #888;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 0 0;
}

#index-footer .right,
#index-footer .right .item {
  -webkit-box-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: flex;
}

#index-footer .right .item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0 80px 0 0;
}

#index-footer .right .item p {
  margin: 0 0 10px;
}

#index-footer .right .item p:last-child {
  margin: 0;
}

#index-footer .right .item.contact-us {
  color: #282d37;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

#index-footer .right .item.contact-us .title {
  color: #3a3a3f;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

#index-footer .right .item .about-us:hover {
  cursor: pointer;
  opacity: 0.8;
}

#index-footer .right .item a {
  color: #888;
  line-height: 20px;
}

#index-footer .right .item a:hover {
  opacity: 0.8;
}

#index-footer .right .item .title {
  color: #333;
  font-weight: 500;
  margin: 0 0 20px;
}

#index-footer .right .service-terms {
  margin: 0 111px 0 0;
}

#index-footer .right .service-terms > a {
  color: rgba(40, 45, 55, 0.8) !important;
}

#index-footer .right .qr-code-box,
#index-footer .right .service-terms .title {
  color: #3a3a3f;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

#index-footer .right .qr-code-box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
}

#index-footer .right .qr-code-box .qr-code {
  height: 90px;
  margin: 10px 0 3px;
  width: 90px;
}

#index-footer.simple {
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  color: #909090;
  padding: 17px 0;
  position: relative;
  text-align: center;
  z-index: 100;
}

#index-footer.simple .links {
  font-size: 14px;
  margin-block: 8px;
}

#index-footer.simple .links a + a {
  margin-left: 40px;
}

#index-footer.simple .copyright {
  font-size: 12px;
  margin: 0;
}

#index-footer .friend-site-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: start;
  -webkit-box-align: start;
  align-items: flex-start;
  border-top: 1px solid #e8e8eb;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1200px;
  padding-bottom: 47px;
  width: 100%;
}

#index-footer .friend-site-container label {
  color: #353535;
  font-size: 16px;
  font-weight: bold;
  height: 22px;
  line-height: 22px;
  margin: 10px 0 20px;
}

#index-footer .friend-site-container .friend-site-link-list {
  -webkit-box-pack: start;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
}

#index-footer .friend-site-container .friend-site-link-list .friend-site-link-li {
  margin-right: 32px;
}

#index-footer .friend-site-container .friend-site-link-list .friend-site-link-li a {
  color: #353535;
  cursor: pointer;
  font-size: 15px;
  opacity: 0.62;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#index-footer .friend-site-container .friend-site-link-list .friend-site-link-li a:hover {
  color: #1890ff;
  opacity: 1;
}
</style>
